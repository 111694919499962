import { Fragment, useContext, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import { CategoriesContext } from "../../../contexts/categories.context";

import { ReactComponent as RopeLogo } from "../../../assets/logo.svg";
import { signOutUser } from "../../../utils/firebase/firebase.utils";

import "./navigation.styles.scss";
import CartIcon from "../../cart-icon/cart-icon.component";
import { UserContext } from "../../../contexts/user.context";
import { ReactComponent as BurgerIcon } from "../../../assets/burger-menu-icon.svg";
import BurgerMenu from "../../burger-menu/burger-menu.component";
import SearchBox from "../../search-box/search-box.component";
import { useEffect } from "react";
import SearchCard from "../../search-card/search-card.component";
import Footer from "../../footer/footer.component";

const Navigation = () => {
  const { categoriesMap } = useContext(CategoriesContext);
  const { currentUser, isAdmin } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [SearchIsVisible, setSearchIsVisible] = useState(false);
  const [discountIsVisible, setDiscountIsVisible] = useState(true);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const toggleBurger = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    const allItems = Object.keys(categoriesMap).flatMap((key) => {
      // Directly flatten and return all items under the current key
      return categoriesMap[key].items.flatMap((category) => category.items);
    });
    setItems(allItems);
  }, [categoriesMap]);

  useEffect(() => {
    if (searchField === "") {
      setFilteredItems([]);
    } else {
      const newFilteredItems = items.filter((monster) => {
        return monster.name.toLocaleLowerCase().includes(searchField);
      });
      setFilteredItems(newFilteredItems);
      console.log(newFilteredItems);
    }
  }, [items, searchField]);

  const onSearchChange = (event) => {
    if (!event) {
      setSearchField("");
      setSearchIsVisible(true);
    } else {
      const searchFieldString = event.target.value.toLocaleLowerCase();
      setSearchField(searchFieldString);
    }
  };

  const onFocus = () => {
    console.log("gained");
    setSearchIsVisible(true);
  };

  const onFocusLost = () => {
    setSearchIsVisible(false);
  };

  return (
    <Fragment>
      <div className="page-container">
        <div className="sticky-navigation">
          <div className="navigations-container">
            {isOpen && (
              <div className="mobile-navigation-container">
                <BurgerMenu onToggle={toggleBurger}>
                  {Object.keys(categoriesMap).map(
                    (key) =>
                      categoriesMap[key].show && (
                        <div className="navigation-item-container" key={key}>
                          <div className="navigation-item">
                            <span key={key} className="navigation-key">
                              {/* <Link
                                className="nav-link"
                                to={`/${key.replaceAll(" ", "-")}`}
                                onClick={toggleBurger}
                              ></Link> */}
                              {key.toUpperCase()}
                            </span>
                            <div className="arrow-container">
                              {categoriesMap[key] && (
                                <div className="dropdown-arrow">&#10095;</div>
                              )}
                            </div>
                          </div>
                          <div className="dropdown-content">
                            {categoriesMap[key].items &&
                              categoriesMap[key].items.map((category) => (
                                <Link
                                  to={`/shop/${category.title.replaceAll(
                                    " ",
                                    "-"
                                  )}`}
                                  onClick={toggleBurger}
                                >
                                  <span
                                    key={category.title}
                                    className="dropdown-item"
                                  >
                                    {category.title}
                                  </span>
                                </Link>
                              ))}
                          </div>
                        </div>
                      )
                  )}
                </BurgerMenu>
              </div>
            )}
            <div className="navigation-container">
              <div className="navigation">
                {(
                  <div className={`logo-title-container ${isOpen ? "" : "center-middle"}`}>
                    <Link className="logo-title-link" to="/">
                      <div className="logo-container">
                        <RopeLogo className="logo" />
                      </div>
                      <h1 className="title">Štrikarija</h1>
                    </Link>
                  </div>
                )}

                <div className="nav-links-container">
                  <div className="burger">
                    <BurgerIcon onClick={toggleBurger} />
                  </div>
                  <div className="header-links">
                    {/* <div className="nav-links"> */}
                    {/* <Link className="nav-link" to="/shop">
                        VSI IZDELKI
                      </Link>
                      <Link className="nav-link" to="/contact">
                        KONTAKT
                      </Link> */}

                    {/* {currentUser ? (
                        <span className="nav-link" onClick={signOutUser}>
                          SIGN OUT
                        </span>
                      ) : (
                        <div />
                      )}
                      {isAdmin ? <h1>Works</h1> : <div />} */}
                    {/* </div> */}
                    <div className="search-box-items-container">
                      <SearchBox
                        onChangeHandler={onSearchChange}
                        text={searchField}
                        onFocus={onFocus}
                      />
                      {filteredItems.length > 0 && SearchIsVisible && (
                        <div className="filtered-items-list">
                          <div className="remove-button" onClick={onFocusLost}>
                            &#10005;
                          </div>
                          {(filteredItems.length <= 5
                            ? filteredItems
                            : filteredItems.slice(0, 5)
                          ).map(
                            (item) =>
                              item.id && (
                                <SearchCard
                                  product={item}
                                  onClickHandler={onSearchChange}
                                />
                              )
                          )}
                        </div>
                      )}
                    </div>
                    {/*currentUser ? (<div className="navigation-item">
                <Link to={`/${key.replaceAll(" ", "-")}`}>
                  <span className="navigation-key">{key.toUpperCase()}</span>
                  </Link>
                  {categoriesMap[key] && (
                    <div className="dropdown-arrow">&#10095;</div>
                  )}
                </div>
          ) : (
            <Link className="nav-link" to="/auth">
              SIGN IN
            </Link>
            

          ) */}
                  </div>
                  <div className="checkout">
                    <Link to="/checkout">
                      <CartIcon />
                    </Link>
                  </div>
                </div>
              </div>
              <nav className="navigation-pages">
                {Object.keys(categoriesMap).map(
                  (key) =>
                    categoriesMap[key].show && (
                      <div className="navigation-item-container" key={key}>
                        <div className="navigation-item">
                          <span key={key} className="navigation-key">
                            <Link
                              className="nav-link"
                              to={`/shop/${key.replaceAll(" ", "-")}`}
                            >
                              {key.toUpperCase()}
                            </Link>
                          </span>
                          <div className="arrow-container">
                            {categoriesMap[key] && (
                              <div className="dropdown-arrow">&#10095;</div>
                            )}
                          </div>
                        </div>
                        <div className="dropdown-content">
                          {categoriesMap[key].items &&
                            categoriesMap[key].items.map((category) => (
                              <Link
                                to={`/shop/${category.title.replaceAll(
                                  " ",
                                  "-"
                                )}`}
                              >
                                <span
                                  key={category.title}
                                  className="dropdown-item"
                                >
                                  {category.title}
                                </span>
                              </Link>
                            ))}
                        </div>
                      </div>
                    )
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className="cc-container">
        <div className={`content-container ${isHomePage ? "home" : ""}`}>
          <div className="background-container" />
          <div
            className={`discount ${discountIsVisible ? "" : "hidden"} ${
              isHomePage ? "discount-home" : ""
            }`}
          >
            <div className="discount-text">
              Na celoten nakup velja 20% popust!
            </div>
            <div
              className="discount-close"
              onClick={() => setDiscountIsVisible(false)}
            >
              X
            </div>
          </div>
          <div className={`content ${isHomePage ? "home" : ""}`}>
            <div className="outlet">
            <Outlet />
            </div>
            <div className={`main-footer-container ${isOpen ? "" : "hidden-footer"}`}>

              <Footer />
              </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default Navigation;
