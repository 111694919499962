import ColorPicker from "../color-picker/color-picker.component";
import "./product-card.styles.scss"; 

import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  const { imageUrl, name, price, colors, length } = product;
  
  return (
    <Link to={`/items/${name.replaceAll(" ", "_")}`}>
      <div className="product-card-container">
        <div className="image-container">
          <img className="product-image" src={imageUrl.replace("www", "b2b")} alt={`${name}`} />
        </div>
        <div className="name-price-color-container">
          {colors ? (
            <ColorPicker colors={colors} />
          ) : (
            <div className="color-picker-replace"></div>
          )}
          <div className="name-price-container">
            <span className="name">{name}</span>
            <span className="price">{((product.basePrice ?product.basePrice :0) + price).toFixed(2)}€{!product.basePrice && length && length.length > 1 ? '/m': ''}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
